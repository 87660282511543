import React from "react";
import { graphql } from "gatsby";

import { HeroLayout } from "../components/hero-layout";
import Layout from "../components/layout";
import SEO from "../components/seo";

class SupportPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle} withMaxWidth={false}>
        <SEO title="Support" />
        <HeroLayout>
            <div className="text-content-container">
            <p>
                For all questions and support, email us at <a href={"mailto:support@remeet.com"}>support@remeet.com</a>
            </p>
            </div>
        </HeroLayout>
      </Layout>
    );
  }
}

export default SupportPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
